import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "a-disabled-state-is-applied-to-a-component-when-the-user-is-not-allowed-to-interact-with-the-component-due-to-either-permissions-dependencies-or-pre-requisites-disabled-states-completely-remove-the-interactive-function-of-a-component"
    }}>{`A `}<em parentName="h3">{`disabled state`}</em>{` is applied to a component when the user is not allowed to interact with the component due to either permissions, dependencies, or pre-requisites. Disabled states completely remove the interactive function of a component.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Disabled variations</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Default disabled</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Read-only</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Hidden</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "disabled-variations"
    }}>{`Disabled variations`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Variation`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Default disabled`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cannot be clicked, selected or interacted with. It is not read by a screen reader and takes on the default disabled visual style.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Read-only`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The user cannot interact with it but content is still readable and accessible to a screen reader. The visual style should contain no interactive indicators such as `}<inlineCode parentName="td">{`$interactive-01`}</inlineCode>{`, hover states, or text embellishments (i.e., underlines).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Hidden`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The component is completely hidden from view. The user does not know the option is there.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "default-disabled"
    }}>{`Default disabled`}</h2>
    <p>{`A `}<em parentName="p">{`default disabled`}</em>{` state is used when a component is temporarily disabled due to dependencies (when one piece of software relies on another one) or pre-requisites. This scenario is a temporary state change that is most commonly triggered by a user’s action or inaction. Once the dependencies have been resolved and/or the pre-requisites have been fulfilled, the default disabled component returns to its enabled state. In a temporarily disabled scenario the component should never fully disappear from the user’s view.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "49.32065217391305%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Default disabled example on the right",
        "title": "Default disabled example on the right",
        "src": "/static/b703dce65979fa385b218258f4110f88/fb070/disabled-1.png",
        "srcSet": ["/static/b703dce65979fa385b218258f4110f88/d6747/disabled-1.png 288w", "/static/b703dce65979fa385b218258f4110f88/09548/disabled-1.png 576w", "/static/b703dce65979fa385b218258f4110f88/fb070/disabled-1.png 1152w", "/static/b703dce65979fa385b218258f4110f88/c3e47/disabled-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "style"
    }}>{`Style`}</h3>
    <p>{`Default disabled states are most commonly styled by a decrease in opacity with no hover state change and `}<inlineCode parentName="p">{`not-allowed`}</inlineCode>{` cursor applied. Refer to each individual component for the accurate disabled state.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default disabled style`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Component`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50% opacity`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Text`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25% opacity`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Icons`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50% opacity`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Hover`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Cursor`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not-allowed`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <br />
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "49.32065217391305%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Default disabled style examples",
        "title": "Default disabled style examples",
        "src": "/static/f1b6295dc34371e06bcb174c3c19ddb1/fb070/disabled-2.png",
        "srcSet": ["/static/f1b6295dc34371e06bcb174c3c19ddb1/d6747/disabled-2.png 288w", "/static/f1b6295dc34371e06bcb174c3c19ddb1/09548/disabled-2.png 576w", "/static/f1b6295dc34371e06bcb174c3c19ddb1/fb070/disabled-2.png 1152w", "/static/f1b6295dc34371e06bcb174c3c19ddb1/c3e47/disabled-2.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "additional-warning"
    }}>{`Additional warning`}</h3>
    <p>{`An `}<a parentName="p" {...{
        "href": "/components/notification/code"
      }}>{`inline warning notification`}</a>{` can be shown in cases where a temporarily disabled item affects multiple items or the primary action of the flow. The notification should describe how the user can enable or re-enable the disabled component.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "50.27173913043478%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Additional warning with default disabled example",
        "title": "Additional warning with default disabled example",
        "src": "/static/23d98ebd3647ec1f05c1e98216fd84fc/fb070/disabled-3.png",
        "srcSet": ["/static/23d98ebd3647ec1f05c1e98216fd84fc/d6747/disabled-3.png 288w", "/static/23d98ebd3647ec1f05c1e98216fd84fc/09548/disabled-3.png 576w", "/static/23d98ebd3647ec1f05c1e98216fd84fc/fb070/disabled-3.png 1152w", "/static/23d98ebd3647ec1f05c1e98216fd84fc/c3e47/disabled-3.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "read-only"
    }}>{`Read-only`}</h2>
    <p>{`In scenarios where the content of a disabled component or element is still relevant to the user or important to task completion, then the `}<em parentName="p">{`read-only`}</em>{` variation is used. This allows the user to read the information but not interact with or change it. Read-only content should always be accessible to a screen reader.`}</p>
    <h3 {...{
      "id": "style-1"
    }}>{`Style`}</h3>
    <p>{`The visual style of the read-only states vary by component but should never contain any interactive indicators such as `}<inlineCode parentName="p">{`$brand-01`}</inlineCode>{` color usage, hover states, or text embellishments (i.e., underlines).`}</p>
    <h2 {...{
      "id": "hidden"
    }}>{`Hidden`}</h2>
    <p>{`The `}<em parentName="p">{`hidden disabled`}</em>{` variation is used when something or someone does not have permission to view, interact with, or take action on an element of the UI. This variation completely hides the component, page, action, etc. from the user’s interface. The only way to enable the hidden element and have it resurfaced on the UI is to change the assigned permission.`}</p>
    <p>{`For example, when a user is the organization owner they are allowed to add members to the organization. Any users that are not an organization owner would not be shown the “Add member” button on a team directory page. Once the user is made an organization owner, `}<strong parentName="p">{`then and only then`}</strong>{` will the button be visible.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "53.53260869565217%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of hidden disabled content on the right",
        "title": "Example of hidden disabled content on the right",
        "src": "/static/dd0f08b9df150798e58ad3981f1ed039/fb070/disabled-4.png",
        "srcSet": ["/static/dd0f08b9df150798e58ad3981f1ed039/d6747/disabled-4.png 288w", "/static/dd0f08b9df150798e58ad3981f1ed039/09548/disabled-4.png 576w", "/static/dd0f08b9df150798e58ad3981f1ed039/fb070/disabled-4.png 1152w", "/static/dd0f08b9df150798e58ad3981f1ed039/c3e47/disabled-4.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      